import React from "react"
import { motion } from "framer-motion"

const TextAnimation = () => {
    const textVariants = {
        hidden: { opacity: 0, y: "-100vh" },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 1, type: "spring", stiffness: 60 },
        },
        hover: {
            scale: 1.1,
            textShadow: "0px 0px 8px rgba(255,255,255,0.8)",
            boxShadow: "0px 0px 8px rgba(255,255,255,0.8)",
        },
    }

    return (
        <motion.div
            variants={textVariants}
            initial="hidden"
            animate="visible"
            whileHover="hover"
            style={{
                fontSize: "2rem",
                textAlign: "center",
                margin: "2rem",
                padding: "1rem",
                borderRadius: "8px",
                cursor: "pointer",
            }}
        >
            Animovaný text
        </motion.div>
    )
}

export default TextAnimation
